import React, { useState } from "react";
import { clsx } from "clsx";

import EmailIcon from "assets/images/email-icon.svg";
import Icon from "components/Icons";

const FormInput = ({
  type = "text",
  label,
  value,
  onChange,
  placeholder,
  icon,
  pattern,
  required,
  minLength,
  maxLength,
  screen = "desktop",
  primaryBorder = false,
  readOnly = false,
  isInvalid = false,
  invalidMessage = "",
  variant = "default",
  ...props
}) => {
  const [inputType, setInputType] = useState(type);

  const handleTogglePassword = () => {
    setInputType((prevType) => (prevType === "password" ? "text" : "password"));
  };

  let inputElement = null;

  if (
    inputType === "text" ||
    inputType === "password" ||
    inputType === "email" ||
    inputType === "number" ||
    inputType === "tel"
  ) {
    inputElement = (
      <div className="w-full">
        {label && (
          <label
            className={clsx({
              "block font-bold mb-3": screen === "desktop",
              "text-sm font-medium mb-1": screen === "mobile",
            })}
          >
            {label}
          </label>
        )}
        <span className="relative">
          <input
            type={inputType}
            className={clsx("block w-full outline-none", {
              "py-2": true, // Add vertical padding
              "text-sm text-gray-800 leading-[21.5px] font-normal rounded-none":
                screen === "mobile",
              "pr-10": icon,
              "border-st-primary": primaryBorder && variant === "default",
              "border-st-gray": !primaryBorder && variant === "default",
              "border-red-600 bg-red-100 border-2":
                isInvalid && variant === "default",
              "focus:border-st-primary": !isInvalid && variant === "default",
              "text-black rounded-lg py-3 px-3": variant === "contained",
              "text-[rgba(0,0,0,0.5)] bg-transparent border-b-2 focus:border-b-[3px] rounded-none":
                variant === "default",
            })}
            placeholder={placeholder}
            value={value} // Ensure this value prop is provided
            onChange={onChange} // Attach the onChange handler
            pattern={pattern}
            minLength={minLength}
            maxLength={maxLength}
            required={required}
            readOnly={readOnly}
            {...props}
          />
          {icon === "password" && (
            <button
              type="button"
              className="absolute bottom-0 translate-y-[-50%] text-st-gray right-0 flex items-center px-3 focus:outline-none"
              onClick={handleTogglePassword}
            >
              {inputType === "text" ? (
                <Icon name="eyeClose" />
              ) : (
                <Icon name="eye" />
              )}
            </button>
          )}
          {icon === "mail" && (
            <button
              type="button"
              className="absolute bottom-0 translate-y-[-50%] text-st-gray right-0 flex items-center px-3 focus:outline-none"
            >
              <img src={EmailIcon} alt="Mail icon" width={20} height={20} />
            </button>
          )}
        </span>
        {invalidMessage && isInvalid && (
          <span className="text-red-500 pt-1 text-sm inline-block">
            {invalidMessage}
          </span>
        )}
      </div>
    );
  } else if (inputType === "radio") {
    inputElement = (
      <div className="w-full flex items-center gap-2">
        <input type={inputType} id="radio" />
        <label
          htmlFor="radio"
          className="flex justify-center items-center text-xs text-opacity-50 cursor-pointer"
        >
          {label}
        </label>
      </div>
    );
  }

  return inputElement;
};

export default FormInput;
