import "./index.scss";

import Navbar from "components/NavBar";
import FormInput from "components/FormInput";
import { useNavigate } from "react-router-dom";
import { useCallback, useLayoutEffect, useState } from "react";
import { formatToUSNumber } from "utils/format-to-us-number";



const BannerSection = () => {
  
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const phoneInputRef = useState(null); // Reference to the phone input

  const handleRedirectToSignup = useCallback(
    (e) => {
      e.preventDefault();

      // US phone number regex pattern
      const usPhoneNumberRegex = /^\+1\s\(\d{3}\)\s\d{3}-\d{4}$/;

      // Validate phone number using regex
      if (usPhoneNumberRegex.test(phone)) {
        navigate("/signup", { state: { phone } });
      } else {
        // Set custom validity message
        if (phoneInputRef.current) {
          phoneInputRef.current.setCustomValidity(
            "Please enter your complete phone number"
          );
          phoneInputRef.current.reportValidity(); // Trigger display of the error message
        }
      }
    },
    [navigate, phone, phoneInputRef]
  );

  const handlePhoneNumberChange = (e) => {
    if (e.target.value === "+") {
      return setPhone("");
    }

    const formattedNumber = formatToUSNumber(e.target.value);
    setPhone(formattedNumber);

    // Reset custom validity when the user is typing
    if (phoneInputRef.current) {
      phoneInputRef.current.setCustomValidity(""); // Reset the custom validity
    }
  };

  useLayoutEffect(() => {
    const htmlInput = document.getElementById("phoneNumber");

    // Reference assignment
    phoneInputRef.current = htmlInput;

    // Custom validation messages
    htmlInput.oninvalid = function (e) {
      if (!e.target.value) {
        e.target.setCustomValidity("Please enter your complete phone number");
      } else if (e.target.value.length !== 17) {
        e.target.setCustomValidity("Please enter your complete phone number");
      } else {
        e.target.setCustomValidity(""); // Reset validity
      }
    };

     // Clear or show custom validity on input
    htmlInput.oninput = function (e) {
      const currentInput = e.target.value;

      // Check if the input length is less than the required 17 characters
      if (currentInput.length < 17) {
        e.target.setCustomValidity("Please enter your complete phone number.");
      } else {
        e.target.setCustomValidity(""); // Clear custom validity if the number is complete
      }
    };



  }, []);

  return (
    <section>
      <div className="text-white w-full z-10">
        <Navbar isLandingPage />
      </div>
      <div className="mx-[10px]">
        <div className="relative h-[620px] background container">
          <div className="absolute top-1/2 left-6  md:left-[64px] translate-y-[-50%] text-white">
            <p className="heading-font text-shadow-xl font-bold text-5xl max-w-[70vw] md:max-w-max md:text-7xl sm:text-6xl md:leading-normal sm:leading-snug leading-tight">
            Join the waitlist and be the first to get <br className="hidden md:block" />a free Stemmer Pro trading account
            </p>
            {/* <p className="heading-font mt-5 text-xl leading-8  max-w-[75vw] md:max-w-max ">
              Pre-register now to join the waitlist
            </p> */}
            <form
              onSubmit={handleRedirectToSignup}
              className="flex gap-4 mt-5 md:max-w-[80%] max-w-[80vw]"
            >
              <FormInput
                value={phone}
                type="tel"
                required
                id="phoneNumber"
                autoComplete="off"
                variant="contained"
                maxLength={17}
                minLength={17}
                onChange={handlePhoneNumberChange}
                placeholder="Enter your phone number"
              />
              <button
                className="bg-st-yellow rounded-lg sm:py-1 w-[30%] px-3 py-3"
                type="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BannerSection;
