import Logo from "assets/logos/parent/stemmer_logo_md.jpeg";
import FormInput from "components/FormInput";
import MainLayout from "components/layouts/MainLayout";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";

const ResetPasswordDesktop = ({
  handleSubmit,
  isLoading = false,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
}) => {
  return (
    <MainLayout>
      <div className="bg-st-primary sm:bg-white sm:px-10 w-full">
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="relative flex items-center justify-center">
            <Link to="/">
              <img
                src={Logo}
                alt="Logo"
                width="0"
                height="0"
                sizes="100%"
                className="w-full h-[26px]"
                priority="true"
              />
            </Link>
          </div>
          <div className="max-w-[80%] w-full text-center text-st-gray-text"></div>
        </div>
        <div className="hidden sm:block">
          <h1 className="text-center font-bold text-[32px]">Reset Password</h1>
          <p className="text-center text-sm leading-5 text-gray-500 mb-4">
            Please enter your new password, and confirm it!
          </p>
        </div>
        <form
          className="sm:mt-10 p-4 sm:p-0 rounded-t-3xl sm:rounded-none flex flex-col  justify-between"
          onSubmit={handleSubmit}
        >
          <div>
            <div className="flex flex-col gap-4">
              <FormInput
                label="New Password"
                placeholder="Enter your new password"
                value={password}
                required
                type="password"
                invalidMessage="Invalid Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
              <FormInput
                label="Confirm New Password"
                placeholder="Confirm your new password"
                value={confirmPassword}
                required
                type="password"
                invalidMessage="Passwords do not match"
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mt-[10vh]">
            <button
              disabled={isLoading}
              className="w-full bg-st-primary text-white rounded-lg p-4 flex justify-center"
              type="submit"
            >
              {isLoading ? (
                <RotatingLines
                  visible={true}
                  height="24"
                  width="24"
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                />
              ) : (
                "Change Password"
              )}
            </button>
          </div>
          <div className="mt-7 text-center">
            <p className="text-st-primary text-xs w-max mx-auto">
              <Link to="/login">Back to Login</Link>
            </p>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default ResetPasswordDesktop;
