import Navbar from "components/NavBar";
import ImagePlaceholder from "assets/images/profile_placeholder.webp";
import { RotatingLines } from "react-loader-spinner";
import InviteForm from "components/InviteForm";
import InviteModal from "components/InviteModal";

const Desktop = ({
  contacts,
  isLoading,
  handleInviteButtonClick,
  openInviteModal,
  setOpenInviteModal,
  inviteLink,
  waitlist,
  refetchContacts,
}) => {
  return (
    <main className="bg-white">
      <Navbar variant="dashboard" />
      <section className="py-5 md:py-20 px-5 md:px-16 container">
        <div className="relative flex flex-wrap items-center justify-center gap-4 md:gap-10 w-full">
          {contacts?.map((contact) => {
            return (
              <div
                key={contact?.memberOid}
                className="relative cursor-pointer flex flex-col items-center justify-center"
              >
                <img
                  src={contact?.profilePictureURL || ImagePlaceholder}
                  alt="Avatar"
                  width="0"
                  height="0"
                  sizes="100%"
                  className="md:w-[120px] md:h-[120px] w-[60px] h-[60px] rounded-full"
                />
                <p className="flex justify-center mt-[18px] text-sm sm:text-base">
                  {contact?.name || null}
                </p>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-center gap-4 mt-4">
          {/* <h2>
            Your waitlist number:{" "}
            <span className="text-primary">{waitlist?.waitlistNumber}</span>
          </h2> */}
          <h2 className="font-bold">
            Your waitlist position:{" "}
            <span className="text-primary">{waitlist?.myPosition}</span>
          </h2>
        </div>
        <div className="flex justify-center mb-[62px]">
          <button
            disabled={isLoading}
            className="bg-st-primary text-white rounded-lg sm:w-max py-4 px-5 sm:px-16 mt-8 text-sm sm:text-base min-w-[540px] flex justify-center"
            onClick={handleInviteButtonClick}
          >
            {isLoading ? (
              <RotatingLines
                visible={true}
                height="24"
                width="24"
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
              />
            ) : (
              "Invite your friends & family members"
            )}
          </button>
        </div>
        <div className="flex flex-col items-center gap-8 md:gap-20 mb-7">
          <InviteForm
            title="Pre-register teens"
            description="(children, grand kids, nephews & nieces)"
          />
          <InviteForm
            title="Pre-register kids under 13 years old"
            description="(children, grand kids, nephews & nieces)"
            onFinish={refetchContacts}
          />
        </div>
      </section>
      <InviteModal
        open={openInviteModal}
        setIsOpen={setOpenInviteModal}
        inviteLink={inviteLink}
      />
    </main>
  );
};

export default Desktop;
