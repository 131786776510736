import Navbar from "components/NavBar";
import FaqItem from "components/parent/FaqItem";
import { Helmet } from "react-helmet";
import emailIcon from "assets/images/email-icon.svg";

const HelpCenter = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content="About Stemmer Markets, Using Stemmer Markets, Managing your investment account, Safety and Security, Rules and Policies, Terms and Conditions"
        />
      </Helmet>

      <Navbar isLandingPage />

      <div className="grid grid-cols-1 md:grid-cols-2 container mx-auto gap-8 px-3">
        <div className="md:mt-12 sm:mt-10 border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
          About Stemmer Markets
          </h2>
          <p className="mt-4 text-gray-500">
          Stemmer Markets is an upcoming stock trading broker/dealer
          </p>
        </div>
        <div className="md:mt-12 sm:mt-10 border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
        <h2 className="font-bold text-2xl text-[#1D9BF0]">
              Contact us 
          </h2>
          <div className="mt-4 [&>div]:mb-2">
            <p className="mt-4 ">
              Share your excitement with us
            </p>
             <div className="flex mt-2 gap-2 items-center">
              <img
                src={emailIcon}
                alt=""
              />
              <span className="text-gray-500">
                support@stemmer-markets.com

              </span>

             </div>
          </div>
        </div>
        <div className="border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
            Using Stemmer Markets
          </h2>
          <div className="mt-4 [&>div]:mb-2">
            <FaqItem
              question={"Why should I pre-register now and join the waitlist"}
              answer={
                "Pre-registering now has numerous benefits. One benefit is full access to the Stemmer Pro trading account for free. On top of that, those who pre-register will also be able to 	enjoy exclusive benefits in our future products."
              }
            />
            <FaqItem
              question={"What are friends & family networks"}
              answer={
                "Whenever you share the invitation link with someone, that person will become part of your friends & family network. This person will receive pre-registration benefits as well. "
              }
            />
            <FaqItem
              question={"Who can be in my friends & family network"}
              answer={
                "You can invite anyone you like to your friends & family network."
              }
            />
            <FaqItem
              question={
                "Are there any limits on the number of members in the friends & family network"
              }
              answer={
                "There are no limits to the number of members as long as you invite them during the pre-registration phase. We may limit the number of members in the network after pre-	registration is closed."
              }
            />
          </div>
        </div>

        

        <div className="border rounded shadow-3 p-10 min-h-[268px] hover:shadow-2 hover:cursor-pointer">
          <h2 className="font-bold text-2xl text-[#1D9BF0]">
            Managing your trading account
          </h2>
          <div className="mt-4 [&>div]:mb-2">
            <FaqItem
              question={"What is a trading account"}
              answer={
                "A trading account is a brokerage account that allows you to buy and sell stocks"
              }
            />
            <FaqItem
              question={"When can I begin trading"}
              answer={
                "You can begin trading after Stemmer Markets gets licensed as a broker/dealer."
              }
            />
            <FaqItem
              question={"How much does a trading account cost"}
              answer={
                "The Stemmer Pro trading account is absolutely free for everyone that pre-registers now"
              }
            />
            
          </div>
        </div>
        
      </div>
      <div className="h-[100px]">&nbsp;</div>
    </div>
  );
};

export default HelpCenter;
