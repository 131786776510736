import API from "config/API";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    try {
      const response = await API.post("register", user);
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    const response = await API.post("login", user);
    const data = response.data;
    if (data && data.info) {
      return data.info;
    } else return data;
  } catch (err) {
    if (!err.response) {
      throw err;
    }

    if (err?.message) toast.error(err.message);

    return thunkAPI.rejectWithValue(err.response.data);
    // thunkAPI.rejectWithValue(error);
  }
});

export const checkPasswordRecoveryLink = createAsyncThunk(
  "auth/checkpasswordrecoverylink",
  async (data, thunkAPI) => {
    try {
      const response = await API.post("checkpasswordrecoverylink", {
        userId: data.username,
        passcode: data.hash,
      });

      const results = response.data;
      if (results && results.info) {
        return results.info;
      } else return results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const reset = createAsyncThunk(
  "auth/register ",
  async ({ setIsLoading, ...data }, thunkAPI) => {
    try {
      setIsLoading(true);
      const response = await API.put(
        "register ",
        {
          userId: data.message,
          passcode: data.password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `${data.token}`,
          },
        }
      );
      setIsLoading(false);

      const results = response.data;
      if (results && results.info) {
        if (results.info.code === 200) {
          toast.success("Password changed successfully.");
          setTimeout(() => {
            window.location.href = "/#/login";
          }, 1000);

          return results.info;
        }
        toast.error(
          "We are currently unable to change your password. Please try again later."
        );
      } else return results;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const forget = createAsyncThunk(
  "auth/forgotpassword",
  async ({ setIsLoading, ...user }, thunkAPI) => {
    try {
      setIsLoading(true);
      const response = await API.post("forgotpassword", user);
      setIsLoading(false);
      const data = response.data;
      if (data && data.info) {
        if (data.info.loginSuccess.code === 200) {
          toast.success("Email address submitted");
        }

        if (data.info.loginSuccess.code === 401) {
          toast.error("Email address submitted");
        }

        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const getUser = createAsyncThunk("auth/profile", async (thunkAPI) => {
  try {
    const response = await API.get("member");
    const data = response.data;
    if (data && data.info && data.info.member) {
      return data.info.member;
    } else return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }

    if (error?.message) toast.error(error.message);

    return thunkAPI.rejectWithValue(error.response.data);
  }
});

export const updateUser = createAsyncThunk(
  "auth/update-profile",
  async (user, thunkAPI) => {
    try {
      const response = await API.put("member", user);
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      if (error?.message) toast.error(error.message);

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const uploadAvatar = createAsyncThunk(
  "auth/upload-avatar",
  async (formData, thunkAPI) => {
    try {
      const response = await API.post("upload", formData);
      const data = response.data;
      if (data && data.info) {
        if (data.info.code === 201) {
          toast.success("Changes saved");
        }

        return data.info;
      } else return data;
    } catch (error) {
      if (!error.response) {
        throw error;
      }

      if (error?.message) toast.error(error.message);

      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const verifyUser = createAsyncThunk(
  "auth/prospect",
  async (user, thunkAPI) => {
    try {
      const response = await API.post("prospect", user);
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);
export const registerKids = createAsyncThunk(
  "auth/under13register",
  async (user, thunkAPI) => {
    try {
      const response = await API.post("under13register", user);
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const getLinkInvite = createAsyncThunk(
  "auth/invite",
  async (thunkAPI) => {
    try {
      const response = await API.get("prospect");
      const data = response.data;
      if (data && data.info) {
        console.log(">>> Link: ", data.info.url);
        return data.info.url;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const getLinkInvitePost = createAsyncThunk(
  "auth/invite",
  async (params, thunkAPI) => {
    try {
      const response = await API.post("prospect", params);
      const data = response.data;
      if (data && data.info) {
        console.log(">>> Link: ", data.info.url);
        return data.info.url;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

export const getWaitlistPosition = createAsyncThunk(
  "auth/waitlist",
  async (thunkAPI) => {
    try {
      const response = await API.get("waitlist");
      const data = response.data;
      if (data && data.info) {
        return data.info;
      } else return data;
    } catch (err) {
      if (!err.response) {
        throw err;
      }

      if (err?.message) toast.error(err.message);

      return thunkAPI.rejectWithValue(err.response.data);
      // thunkAPI.rejectWithValue(error);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    loading: false,
    errors: null,
    user: null,
    token: null,
    isLogin: false,
    isRegister: false,
    invite_url: null,
    isUploaded: false,
    isRecovery: false,
    recoveryToken: null,
    recoveryMessage: null,
    waitlist: {
      isLoading: false,
      data: null,
    },
  },
  reducers: {
    resetUser: (state) => {
      state.loading = false;
      state.errors = null;
      state.isLogin = false;
      state.isRegister = false;
      state.isUploaded = false;
    },
    resetErrors: (state) => {
      state.errors = null; 
    },
  },
  extraReducers: (builder) => {
    // upload avatar
    builder.addCase(uploadAvatar.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadAvatar.fulfilled, (state, action) => {
      state.loading = false;
      state.isUploaded = true;
    });
    builder.addCase(uploadAvatar.rejected, (state, action) => {
      state.loading = false;
      state.isUploaded = false;
      state.errors = action.error;
    });
    // profile
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
      state.errors = null;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
    });
    // update profile
    builder.addCase(updateUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, action) => {
      state.loading = false;
      state.isUpdated = true;
    });
    builder.addCase(updateUser.rejected, (state, action) => {
      state.loading = false;
      state.isUpdated = false;
      state.errors = action.error;
    });
    // waitlist
    builder.addCase(getWaitlistPosition.pending, (state) => {
      state.waitlist.isLoading = true;
    });
    builder.addCase(getWaitlistPosition.fulfilled, (state, action) => {
      state.waitlist.isLoading = false;
      state.waitlist.data = action.payload.waitlist;
    });
    // register
    builder.addCase(register.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.loading = false;
      const { code } = action.payload.loginSuccess;
      if (code === 200) {
        state.user = action.payload;
        state.isRegister = true;
        state.token =
          (action.payload.loginSuccess && action.payload.loginSuccess.token) ||
          "";
      }
      if (code === 409) {
        state.errors = {
          register:
            "User already exists, please login or try registering a new user",
        };
      }
    });
    builder.addCase(register.rejected, (state, action) => {
      state.loading = false;
      state.errors = action.error;
      state.isRegister = false;
      state.user = null;
    });
    // login
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      const { code } = action.payload.loginSuccess;
      if (code === 200) {
        state.user = action.payload;
        state.isLogin = true;
        state.token =
          (action.payload.loginSuccess && action.payload.loginSuccess.token) ||
          "";
      }
      if (code === 401) {
        state.errors = {
          login: "Login failed, please try again.",
        };
      }
    });
    builder.addCase(checkPasswordRecoveryLink.fulfilled, (state, action) => {
      const payload = action.payload;
      if (payload.code === 200) {
        if (payload.loginSuccess) {
          const { message, token } = payload.loginSuccess;

          state.recoveryToken = token;
          state.recoveryMessage = message;
          state.isRecovery = true;
        } else {
          toast.error(
            "Please go to 'Forgot Password' page and try again. The provided link has expired."
          );
        }
      } else {
        state.recoveryToken = null;
        state.recoveryMessage = null;
        state.isRecovery = false;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.isLogin = false;
      state.user = null;
      state.errors = action.error;
      state.loading = false;
    });
    // verify
    builder.addCase(verifyUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(verifyUser.fulfilled, (state, action) => {
      state.loading = false;
      // state.invite_url = action.payload.url || "";
    });
    builder.addCase(verifyUser.rejected, (state, action) => {
      state.loading = false;
      // state.invite_url = null;
      state.errors = action.err;
    });
    // get link invite
    builder.addCase(getLinkInvite.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLinkInvite.fulfilled, (state, action) => {
      console.log(">> payload: ", action.payload);
      state.loading = false;
      state.invite_url = action.payload;
    });
    builder.addCase(getLinkInvite.rejected, (state, action) => {
      state.loading = false;
      state.invite_url = null;
      state.errors = action.error;
    });
  },
});

export const { resetUser,resetErrors } = authSlice.actions;

export default authSlice.reducer;
