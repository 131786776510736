import WithSpinner from "components/WithSpinner";
import MainLayout from "components/layouts/MainLayout";
import FormEditableInput from "components/FormEditableInput";
import CameraIcon from "assets/images/camera-icon.svg";
import ProfilePicPlaceholder from "assets/images/profile_placeholder.webp";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const Desktop = ({
  user,
  avatar,
  handleFileChange,
  inputFileRef,
  handleClick,
  handleSubmitForm,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  dateOfBirth,
  setDateOfBirth,
  email,
  setEmail,
  isSubmitting,
}) => {
  const navigate = useNavigate();

  return (
    <WithSpinner loading={!user}>
      <MainLayout>
        <div className="bg-st-primary sm:bg-white h-full flex flex-col sm:px-10 w-full">
          <div className="hidden sm:block">
            <h1 className="text-center font-bold text-3xl">Profile Setting</h1>
            {/* <p className="text-xs text-center text-opacity-80 mt-3">
              Easily edit your profile details and preferences.
            </p> */}
          </div>
          <div className="sm:hidden pt-10 pb-20">
            <p className="text-center text-white text-lg font-bold">
              Profile Settings
            </p>
          </div>
          <div className="relative flex-1">
            <div className="absolute sm:relative -top-[100px] sm:top-0 flex justify-center w-full mt-7">
              <div
                key={avatar}
                className="relative w-[152px] h-[152px] sm:w-[220px] sm:h-[220px] shadow-2 rounded-full"
              >
                {avatar ? (
                  <img
                    src={avatar}
                    alt="Profile"
                    width="0"
                    height="0"
                    sizes="100%"
                    loading="lazy"
                    className="w-[220px] h-[220px] rounded-full"
                  />
                ) : (
                  <img
                    src={ProfilePicPlaceholder}
                    alt="Profile"
                    width="0"
                    height="0"
                    sizes="100%"
                    className="rounded-full w-[220px] h-[220px]"
                  />
                )}
                <input
                  type="file"
                  ref={inputFileRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <span className="absolute bottom-4 right-0">
                  <img
                    src={CameraIcon}
                    alt="Camera icon"
                    className="cursor-pointer"
                    width={55}
                    height={55}
                    onClick={handleClick}
                  />
                </span>
              </div>
            </div>
            <form
              onSubmit={handleSubmitForm}
              className="bg-white h-full sm:h-auto flex flex-col justify-between sm:mt-10 p-4 pt-20 sm:p-0 rounded-t-3xl sm:rounded-none"
            >
              <div>
                <div>
                  <FormEditableInput
                    label="First Name"
                    placeholder="David"
                    icon="pencil"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                    minLength={3}
                    maxLength={50}
                  />
                </div>
                <div className="mt-7">
                  <FormEditableInput
                    label="Last Name"
                    placeholder="John"
                    icon="pencil"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                    minLength={3}
                    maxLength={50}
                  />
                </div>
                <div className="mt-7">
                  <FormEditableInput
                    label="Date of Birth"
                    placeholder="MM/DD/YYYY"
                    type="date"
                    value={dateOfBirth}
                    onChange={(e) => setDateOfBirth(e.target.value)}
                    required
                    minLength={3}
                    maxLength={50}
                  />
                </div>
                <div className="mt-7">
                  <FormEditableInput
                    label="Email-Address"
                    placeholder="example@gmail.com"
                    icon="pencil"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    minLength={3}
                    maxLength={100}
                  />
                </div>
              </div>
              <div className="mt-16 flex flex-col gap-[22px] items-center justify-center">
                <button
                  disabled={isSubmitting}
                  type="submit"
                  className="w-full bg-st-primary text-white rounded-lg p-4 flex justify-center"
                >
                  {isSubmitting ? (
                    <RotatingLines
                      visible={true}
                      height="24"
                      width="24"
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                    />
                  ) : (
                    "Save Changes"
                  )}
                </button>
                <button
                  onClick={() => {
                    navigate("/invite");
                  }}
                  className="w-full bg-white border text-st-gray-text rounded-lg p-4 flex justify-center"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    </WithSpinner>
  );
};

export default Desktop;
