import Logo from "assets/logos/parent/stemmer_logo_md.jpeg";
import FormInput from "components/FormInput";
import MainLayout from "components/layouts/MainLayout";
import { useLayoutEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { formatToUSNumber } from "utils/format-to-us-number";
import { isMobileNumber } from "utils/is-mobile-number";

const Desktop = ({
  handleSubmit,
  isLoading = false,
  phoneNumber,
  setPhoneNumber,
  password,
  setPassword,
  isInvalidEmail,
}) => {
  const [isMobileNo, setIsMobileNo] = useState(false);

  useLayoutEffect(() => {
    const htmlInput = document.getElementById("phoneNumber");

    const handleInvalid = (e) => {
      e.target.setCustomValidity('"Email" or "Phone number" is required');
    };

    const handleInput = (e) => {
      e.target.setCustomValidity('');
    };

    htmlInput.addEventListener('invalid', handleInvalid);
    htmlInput.addEventListener('input', handleInput);

    return () => {
      htmlInput.removeEventListener('invalid', handleInvalid);
      htmlInput.removeEventListener('input', handleInput);
    };
  }, []);

  return (
    <MainLayout>
      <div className="bg-st-primary sm:bg-white sm:px-10 w-full">
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="relative flex items-center justify-center">
            <Link to={"/"}>
              <img
                src={Logo}
                alt="Logo"
                width="0"
                height="0"
                sizes="100%"
                className="w-full h-[26px]"
                priority="true"
              />
            </Link>
          </div>
          <div className="max-w-[80%] w-full text-center text-st-gray-text"></div>
        </div>
        <div className="hidden sm:block">
          <h1 className="text-center font-bold text-[30px]">Sign In</h1>
          <h1 className="text-base text-red-600 text-center font-semibold mt-4 text-[30px]">{isInvalidEmail ? isInvalidEmail : ""}</h1>

          
        </div>
        <form
          className="sm:mt-10 p-4 sm:p-0 rounded-t-3xl sm:rounded-none flex flex-col justify-between"
          onSubmit={handleSubmit}
        >
          <div>
            <div>
              <FormInput
                label="Email or Phone"
                placeholder="Enter your email address or phone number"
                value={phoneNumber}
                required
                type="text"
                maxLength={isMobileNo ? 17 : 100}
                // isInvalid={!!isInvalidEmail} // Show invalid state if error exists
                id="phoneNumber" 
                invalidMessage={isInvalidEmail} // Error message from validation
                onChange={(e) => {
                  const value = e?.target?.value
                    ?.replace("+1", "")
                    .replace(")", "")
                    .replace("(", "")
                    .replace(" ", "")
                    .replace("-", "");

                  const isMobile = isMobileNumber(value) && value.length >= 2;
                  if (isMobile) {
                    setIsMobileNo(true);
                  } else {
                    setIsMobileNo(false);
                  }
                  if (isMobile) {
                    setPhoneNumber(formatToUSNumber(value));
                  } else {
                    setPhoneNumber(value);
                  }
                }}
              />
            </div>
            <div className="mt-7">
              <FormInput
                type="password"
                label="Password"
                placeholder="Enter password"
                value={password}
                required
                minLength={8}
                maxLength={50}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="flex justify-between mt-7">
              {/* <FormInput
                type="radio"
                label="Remember me"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              /> */}
              <span />
              <div>
                <p className="text-st-primary text-xs w-max">
                  <Link to={"/forgot"}>Forgot Password?</Link>
                </p>
              </div>
            </div>
          </div>
          <div className="mt-[10vh]">
            <button
              disabled={isLoading}
              className="w-full bg-st-primary text-white rounded-lg p-4 flex justify-center"
              type="submit"
            >
              {isLoading ? (
                <RotatingLines
                  visible={true}
                  height="24"
                  width="24"
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                />
              ) : (
                "Sign in"
              )}
            </button>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default Desktop;
