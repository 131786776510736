// import TeenRegisterBanner from "assets/images/parent-register-banner.webp";
import ParentRegisterInnerBanner from "assets/images/parent-register-inner-banner.webp";

const RegisterBannerSection = () => {
  return (
    <a
      rel="noreferrer"
      href="https://youth.stemmer-markets.ai/"
      target="_blank"
    >
      <section className="max-w-[1440px] ml-auto mr-auto mt-[82px] h-full lg:h-[600px] lg:max-h-[600px] bg-st-primary rounded-md">
        <div className="relative flex flex-col lg:flex-row justify-center items-center lg:justify-between lg:items-start p-14">
          {/* <img
          src={TeenRegisterBanner}
          alt="Parent Register Banner"
          width={0}
          height={0}
          className="w-full h-[600px] rounded-lg overflow-hidden bg-[rgb(27, 20, 41)]"
        /> */}
          <div className="text-white self-start">
            <p className="heading-font font-bold text-2xl sm:text-3xl">
              Teens pre-register here
            </p>
            <p className="h-[3px] bg-white rounded-full w-14 md:w-24 my-5">
              &nbsp;
            </p>
            {/* <p className="sm:text-lg">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries
          </p> */}
          </div>
          <div className="z-10 w-80vw h-auto lg:mr-16 mr-0 sm:w-[481px] sm:h-[502px]">
            <img
              src={ParentRegisterInnerBanner}
              alt="Parent Register Inner Banner"
              width={0}
              height={0}
              sizes="100%"
              className="w-full h-full z-10"
            />
          </div>
        </div>
      </section>
    </a>
  );
};

export default RegisterBannerSection;
