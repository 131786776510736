import React, { useState } from "react";
import FormInput from "components/FormInput";
import { useDispatch } from "react-redux";
import { verifyUser, registerKids } from "stores/auth";
import { RotatingLines } from "react-loader-spinner";
import InviteModal from "components/InviteModal/mobile";
import { formatToUSNumber } from "utils/format-to-us-number";
import { toast } from "react-toastify";
const InviteForm = ({ title, description }) => {
  const dispatch = useDispatch();

  const [isPreRegisterTeens] = useState(() => title === "Pre-register teens");

  const [isTouched, setIsTouched] = useState(false);
  const [isInvalidInput, setIsInvalidInput] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [inviteLink, setInviteLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const validateEmail = (email) => {
    const pattern = /^(?:\+1[-.\s]?)?(?:\(?\d{3}\)?[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
    return pattern.test(email);
  };

  const handlePhoneNumberChange = (e) => {
    if (e.target.value === "+") {
      return setPhoneNumber("");
    }
    const formattedNumber = formatToUSNumber(e.target.value);

    setPhoneNumber(formattedNumber);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form from refreshing the page

    if (isPreRegisterTeens) {
      setIsTouched(true);
      if (!validateEmail(phoneNumber)) {
        return setIsInvalidInput(true);
      }
    }

    setIsLoading(true);
    if (isPreRegisterTeens) {
      dispatch(
        verifyUser({
          prospectPhoneNumber: phoneNumber,
          uriInd: "y",
        })
      )
        .then((res) => {
          if (res?.error?.message) {
            return toast.error(
              "Unable to generate invite link. Please try again."
            );
          }

          setInviteLink(res?.payload?.url);
          setOpenInviteModal(true);
        })
        .finally(() => {
          setIsLoading(false);
          setPhoneNumber("");
        });
    } else {
      dispatch(
        registerKids({
          id: phoneNumber,
        })
      )
        .then((res) => {
          if (res?.error?.message) {
            return toast.error(
              "Unable to generate invite link. Please try again."
            );
          }
        })
        .finally(() => {
          setIsLoading(false);
          setPhoneNumber("");
        });
    }
  };
  return (
    <div className="">
      <div className="flex justify-center flex-col items-center gap-[5px]">
        <p className="font-bold text-sm leading-[21.5px]">{title}</p>
        <p className="text-[rgba(0,0,0,.6)] text-sm font-medium">
          {description}
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mt-[16px]">
          <FormInput
            screen="mobile"
            primaryBorder
            placeholder={
              isPreRegisterTeens ? "Enter phone number" : "Enter Name"
            }
            type="text"
            required
            value={inviteLink ? inviteLink : phoneNumber} // Bind the state to the input value
            readOnly={inviteLink ? true : false}
            isInvalid={isInvalidInput}
            maxLength={isPreRegisterTeens ? 17 : 100}
            invalidMessage="Invalid Phone Number!"
            onChange={(e) => {
              if (isPreRegisterTeens) {
                setIsInvalidInput(false);
                if (!validateEmail(e.target.value) && isTouched) {
                  setIsInvalidInput(true);
                }
                handlePhoneNumberChange(e);
              } else {
                setPhoneNumber(e.target.value);
              }
            }}
          />
        </div>
        <button
          className="bg-st-primary text-white rounded-lg text-sm font-semibold leading-[21.5px] py-[14px] w-full mt-[16px] flex justify-center"
          type="submit"
        >
          {isLoading ? (
            <RotatingLines
              visible={true}
              height="24"
              width="24"
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
            />
          ) : inviteLink ? (
            "Send Invitation Link"
          ) : (
            "Submit"
          )}
        </button>
      </form>
      <InviteModal
        open={openInviteModal}
        setIsOpen={setOpenInviteModal}
        inviteLink={inviteLink}
      />
    </div>
  );
};

export default InviteForm;
