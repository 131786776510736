import Logo from "assets/logos/parent/stemmer_logo_md.jpeg";
import FormInput from "components/FormInput";
import MainLayout from "components/layouts/MainLayout";
import { RotatingLines } from "react-loader-spinner";
import { Link } from "react-router-dom";

const ForgotPassword = ({
  handleSubmit,
  isLoading = false,
  email,
  setEmail,
  isInvalidEmail,
  setIsInvalidEmail,
  isEmailTouched,
  validateEmail,
}) => {
  return (
    <MainLayout>
      <div className="bg-st-primary sm:bg-white sm:px-10 w-full">
        <div className="flex flex-col items-center justify-center gap-3">
          <div className="relative flex items-center justify-center">
            <Link to={"/"}>
              <img
                src={Logo}
                alt="Logo"
                width="0"
                height="0"
                sizes="100%"
                className="w-full h-[26px]"
                priority="true"
              />
            </Link>
          </div>
          <div className="max-w-[80%] w-full text-center text-st-gray-text"></div>
        </div>
        <div className="hidden sm:block">
          <h1 className="text-center font-bold text-[32px]">
            Forgot Password?
          </h1>
        </div>
        <form
          className="sm:mt-10 p-4 sm:p-0 rounded-t-3xl sm:rounded-none flex flex-col justify-between"
          onSubmit={handleSubmit}
        >
          <div>
            <div>
              <FormInput
                label="Email"
                placeholder="Enter your registered email address"
                value={email}
                required
                type="email"
                isInvalid={isInvalidEmail}
                invalidMessage="Invalid Email Address"
                onChange={(e) => {
                  const isValid = validateEmail(e.target.value);
                  if (!isValid && isEmailTouched) {
                    setIsInvalidEmail(true);
                  } else {
                    setIsInvalidEmail(false);
                  }
                  setEmail(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="mt-[10vh]">
            <button
              disabled={isLoading}
              className="w-full bg-st-primary text-white rounded-lg p-4 flex justify-center"
              type="submit"
            >
              {isLoading ? (
                <RotatingLines
                  visible={true}
                  height="24"
                  width="24"
                  strokeColor="white"
                  strokeWidth="5"
                  animationDuration="0.75"
                  ariaLabel="rotating-lines-loading"
                />
              ) : (
                "Submit"
              )}
            </button>
          </div>
          <div className="mt-7 text-center">
            <p className="text-st-primary text-xs w-max mx-auto">
              <Link to="/login">Back to Login</Link>
            </p>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default ForgotPassword;
